import axios from 'axios';
import { baseUrl } from './config';

export const getNonce = async (address: string) => {
    const url = `${baseUrl}/auth/get-nonce/${address.toLowerCase()}`;
    const res = await axios.get(url);
    return {
        SK: res.data.data.SK,
        nonce: res.data.data.nonce,
    };
};
