import { Box, Container, Grid, Paper, Typography } from '@mui/material';
import { IInventoryModel } from '../models/InventoryItem';
import { useEffect, useState } from 'react';
import { getDefaultInventoryItems } from '../api/getDefaultInventoryItems';
import { UserInventories } from '../components/tables/UsersInventoryItems';

export const DefaultInventoryItems = () => {
    const [inventoryItems, setInventoryItems] = useState<IInventoryModel[]>([]);

    useEffect(() => {
        (async () => {
            const res = await getDefaultInventoryItems();
            setInventoryItems(res);
        })();
    }, []);
    return (
        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
                marginTop: '64px',
            }}
        >
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', marginBottom: '15px' }}>
                            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                                Default Inventory Items
                            </Typography>{' '}
                        </Paper>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                            <UserInventories inventoryItems={inventoryItems} />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};
