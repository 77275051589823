import { Box, Container, Grid, Paper, Typography as Text } from '@mui/material';
import { IInventoryModel } from '../models/InventoryItem';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ILandModel } from '../models/Land';
import { InventoryItemLand } from '../models/InventoryItemLand';
import { getInventoryItemDetails } from '../api/getInventoryItemDetails';
import { LandsTable } from '../components/tables/LandsTable';

export const InventoryItemDetails = () => {
    const params = useParams();

    const [inventoryItem, setInventoryItem] = useState<IInventoryModel | undefined>(undefined);
    const [lands, setLands] = useState<ILandModel[]>([]);
    const [inventoryItemLands, setInventoryItemLands] = useState<InventoryItemLand[]>([]);

    useEffect(() => {
        (async () => {
            if (!params.id || !params.sk) return;
            const res = await getInventoryItemDetails(params.id, params.sk);
            setInventoryItem(res.inventoryItem);
            setLands(res.lands);
            setInventoryItemLands(res.assets);
        })();
    }, [params]);
    return (
        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
                marginTop: '64px',
            }}
        >
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', marginBottom: '15px' }}>
                            <Text component="h2" variant="h6" color="InfoText" gutterBottom>
                                Inventory Item Details
                            </Text>{' '}
                        </Paper>
                        {inventoryItem && (
                            <Paper
                                sx={{ p: 2, display: 'flex', flexDirection: 'column', marginBottom: '15px' }}
                            >
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <Text component="h2" variant="h6" color="InfoText">
                                            Title:
                                        </Text>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Text component="h2" variant="h6" color="InfoText">
                                            {inventoryItem.title}
                                        </Text>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Text component="h2" variant="h6" color="InfoText">
                                            Vertices count:
                                        </Text>{' '}
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Text component="h2" variant="h6" color="InfoText">
                                            {inventoryItem.verticesCount}
                                        </Text>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Text component="h2" variant="h6" color="InfoText">
                                            ObjectId:
                                        </Text>{' '}
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Text component="h2" variant="h6" color="InfoText">
                                            {inventoryItem.objectId}
                                        </Text>
                                    </Grid>
                                    {inventoryItemLands && inventoryItemLands.length > 0 && (
                                        <>
                                            <Grid item xs={6}>
                                                <Text
                                                    component="h2"
                                                    variant="h6"
                                                    color="InfoText"
                                                    gutterBottom
                                                >
                                                    Used in builder mode
                                                </Text>{' '}
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Text
                                                    component="h2"
                                                    variant="h6"
                                                    color="InfoText"
                                                    gutterBottom
                                                >
                                                    {inventoryItemLands.length} times
                                                </Text>{' '}
                                            </Grid>

                                            <Grid item xs={6}>
                                                <Text
                                                    component="h2"
                                                    variant="h6"
                                                    color="InfoText"
                                                    gutterBottom
                                                >
                                                    Used in{' '}
                                                </Text>{' '}
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Text
                                                    component="h2"
                                                    variant="h6"
                                                    color="InfoText"
                                                    gutterBottom
                                                >
                                                    {
                                                        inventoryItemLands
                                                            .map((s) => s.landId)
                                                            .filter((value, index, array) => {
                                                                return array.indexOf(value) === index;
                                                            }).length
                                                    }{' '}
                                                    different lands
                                                </Text>
                                            </Grid>
                                        </>
                                    )}
                                    <Grid item xs={12}>
                                        <Text
                                            align="right"
                                            component="h2"
                                            variant="h6"
                                            color="InfoText"
                                            gutterBottom
                                        >
                                            <Link to={inventoryItem.url} target="_blank">
                                                Download
                                            </Link>
                                        </Text>{' '}
                                    </Grid>
                                </Grid>
                            </Paper>
                        )}
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                            {lands && lands.length > 0 && (
                                <>
                                    <Text component="h2" variant="h6" color="InfoText" gutterBottom>
                                        Used in following lands:
                                    </Text>{' '}
                                    <LandsTable lands={lands} />
                                </>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};
