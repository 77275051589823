///user/inventory-items?generalInventory=true

import axios from 'axios';
import { baseUrl } from './config';
import { IInventoryModel } from '../models/InventoryItem';

export const getDefaultInventoryItems = async () => {
    const url = `${baseUrl}/user/inventory-items?generalInventory=true`;
    const res = await axios.get(url);
    return res.data.data.items as IInventoryModel[];
};
