interface Config {
    [env: string]: {
        URL: string;
    };
}

const config: Config = {
    local: {
        URL: 'http://localhost:3000/local',
    },
    development: {
        URL: 'https://52p9hpjn79.execute-api.eu-central-1.amazonaws.com/dev',
    },
    staging: {
        URL: 'https://jmkre9md1i.execute-api.eu-central-1.amazonaws.com/stage',
    },
    production: {
        URL: 'https://rcc3ae8yb3.execute-api.eu-central-1.amazonaws.com/production',
    },
};

const env = process.env.REACT_APP_STAGE?.trim() ?? process.env.NODE_ENV;
export default config[env];
