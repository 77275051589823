import { Button, Switch, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { User } from '../../models/User';
import { Link } from 'react-router-dom';

interface IUserProps {
    users: User[];
    loadMore: () => Promise<void>;
    onSwitchAdmin: (userId: string, isAdmin: boolean) => Promise<void>;
}

export const Users = ({ users, loadMore, onSwitchAdmin }: IUserProps) => {
    return (
        <>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Users
            </Typography>{' '}
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Username</TableCell>
                        <TableCell>Wallet</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Verified</TableCell>
                        <TableCell>Admin</TableCell>
                        <TableCell align="right">Details</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map((row, i) => (
                        <TableRow key={row._id}>
                            <TableCell>{i + 1}</TableCell>
                            <TableCell>{row.nickName}</TableCell>
                            <TableCell>{row._id.replace('User-', '')}</TableCell>
                            <TableCell>{row.email}</TableCell>
                            <TableCell>{row.emailVerified ? 'true' : 'false'}</TableCell>
                            <TableCell>
                                <Switch
                                    checked={row.isAdmin}
                                    onChange={(e) => onSwitchAdmin(row._id, e.target.checked)}
                                />
                            </TableCell>
                            <TableCell align="right">
                                <Link to={`/user/${row._id}`}>Details</Link>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Button color="primary" href="#" onClick={loadMore} sx={{ mt: 3 }}>
                Load more
            </Button>
        </>
    );
};
