import axios from 'axios';
import React, { ReactElement, useEffect, useState } from 'react';

import config from '../config/config';
import { firebaseAuth } from './AuthProvider';

interface JwtInterceptorProps {
    children: ReactElement<any, any>;
}

export function JwtInterceptor({ children }: JwtInterceptorProps) {
    const [init, setInit] = useState(false);

    useEffect(() => {
        axios.interceptors.request.use((request) => {
            const idToken = localStorage.getItem('idToken');
            const isApiUrl = request.url?.startsWith(config.URL);

            if (idToken && isApiUrl) {
                request.headers!.Authorization = `Bearer ${idToken}`;
            }
            return request;
        });

        axios.interceptors.response.use(
            (response) => {
                return response;
            },
            async (error) => {
                if (error.response.status === 401) {
                    const token = await firebaseAuth.currentUser?.getIdToken(true);
                    if (!token) {
                        return Promise.reject(error);
                    }
                    localStorage.setItem('idToken', token);
                    return axios.request(error.config);
                } else {
                    const errorData = error.response.data.data;
                    let message = 'Something went wrong';

                    if (errorData) {
                        message = errorData.message || errorData.errors.join(' ');
                    } else if (error.response.data.message) {
                        message = error.response.data.message;
                    }

                    console.error(message);

                    return Promise.reject(new Error(message));
                }
            },
        );

        /**
         * We need to wait for the middlewars to be inited.
         */
        setInit(true);
    }, []);

    return <>{init && <>{children}</>}</>;
}
