import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEthereumProvider } from '../hooks/useEthereumProvider';
import { getNonce } from '../api/getNonce';
import { authorize } from '../api/authorize';
import { User, getAuth, signInWithCustomToken } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import firebaseConfig from '../config/firebase.config';
import jwtDecode from 'jwt-decode';

export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(firebaseApp);

interface IAuthContext {
    token: string | null;
    onLogin: () => Promise<void>;
    onLogout: () => Promise<void>;
    initializing: boolean;
    isAdmin: boolean | undefined;
}

const AuthContext = React.createContext<IAuthContext | null>(null);

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { ethereumProvider } = useEthereumProvider();
    const [token, setToken] = React.useState<string | null>(null);
    const [initializing, setInitializing] = useState<boolean>(true);
    const [isAdmin, setIsAdmin] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        const authChangeListener = firebaseAuth.onAuthStateChanged(async (user) => {
            if (user) {
                localStorage.setItem('idToken', (user as any).accessToken);
                const token = await user.getIdToken();
                setToken(token);
                localStorage.setItem('idToken', token);
            } else {
                setToken(null);
            }
            localStorage.setItem('logged_in', (!!user).toString());
            setInitializing(false);
        });

        const idTokenChangeListener = firebaseAuth.onIdTokenChanged(async (user) => {
            const idToken = await user?.getIdToken();
            if (idToken) {
                localStorage.setItem('idToken', idToken);
            } else {
                localStorage.removeItem('idToken');
            }
        });

        return () => {
            authChangeListener();
            idTokenChangeListener();
        };
    }, []);

    const handleLogin = async () => {
        const signer = await ethereumProvider?.getSigner();
        const address = signer?.address;
        if (!address) return;
        const { nonce, SK } = await getNonce(address);

        const sign = await signer.signMessage(nonce);

        const token = await authorize(address, SK, sign);

        const res = await signInWithCustomToken(firebaseAuth, token);
        const idToken = await res.user.getIdToken();
        const decoded = jwtDecode(idToken) as any;

        if (decoded.authType !== 'Admin') {
            setIsAdmin(false);
            await firebaseAuth.signOut();
        } else {
            setToken(idToken);

            if (decoded.authType === 'Admin') {
                const origin = location.state?.from?.pathname || '/users';
                navigate(origin);
            }
        }
    };

    const handleLogout = async () => {
        setToken(null);
        await firebaseAuth.signOut();
    };

    const value = {
        token,
        onLogin: handleLogin,
        onLogout: handleLogout,
        initializing: initializing,
        isAdmin,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return React.useContext(AuthContext);
};
