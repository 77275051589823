import { Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { IInventoryModel } from '../../models/InventoryItem';

interface IUserProps {
    inventoryItems: IInventoryModel[];
    loadMore?: () => Promise<void>;
}

export const UserInventories = ({ inventoryItems, loadMore }: IUserProps) => {
    return (
        <>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Inventory Items
            </Typography>{' '}
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Img</TableCell>
                        <TableCell>File Name</TableCell>
                        <TableCell>Title</TableCell>
                        <TableCell>Vertices</TableCell>
                        <TableCell>ObjectId</TableCell>
                        <TableCell align="right">Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {inventoryItems.map((row) => (
                        <TableRow key={row._id}>
                            <TableCell>{row.thumbnail && <img src={row.thumbnail.url} />}</TableCell>
                            <TableCell>{row.fileName}</TableCell>
                            <TableCell>{row.title}</TableCell>
                            <TableCell>{row.verticesCount}</TableCell>
                            <TableCell>{row.objectId}</TableCell>
                            <TableCell align="right">
                                <Link to={row.url} target="_blank">
                                    Download
                                </Link>
                                <br />
                                <Link to={`/inventory-item/${row._id}/details/${row.SK}`}>Details</Link>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {loadMore && (
                <Button color="primary" href="#" onClick={loadMore} sx={{ mt: 3 }}>
                    Load more
                </Button>
            )}
        </>
    );
};
