import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { ILandModel } from '../../models/Land';
import { Link } from 'react-router-dom';

interface IUserMetaversesPropes {
    lands: ILandModel[];
}

export const UserMetaverses = ({ lands }: IUserMetaversesPropes) => {
    return (
        <>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Users lands
            </Typography>{' '}
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Logo</TableCell>
                        <TableCell>Title</TableCell>
                        <TableCell>Pos</TableCell>
                        <TableCell>Parent</TableCell>
                        <TableCell align="right">Details</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {lands.map((row) => (
                        <TableRow key={row._id}>
                            <TableCell>{row.logo && <img src={row.logo.url} width="50px" />}</TableCell>
                            <TableCell>{row.title}</TableCell>
                            <TableCell>{row.pos}</TableCell>
                            <TableCell>
                                {row.parentLandId !== 'NOT_EXIST' ? (
                                    <Link to={`/land/${row.parentLandId}`}> Parent Land </Link>
                                ) : (
                                    'N/A'
                                )}
                            </TableCell>
                            <TableCell align="right">
                                <Link to={`/land/${row._id}`}>Details</Link>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
};
