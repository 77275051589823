import { ethers } from 'ethers';
import { useEffect, useState } from 'react';

declare global {
    interface Window {
        ethereum: any;
    }
}

export function useEthereumProvider() {
    const [ethereumProvider, setEthereumProvider] = useState<ethers.BrowserProvider | undefined>(undefined);

    useEffect(() => {
        if (!(window as any).ethereum) {
            setEthereumProvider(undefined);
            return;
        }
        const provider = new ethers.BrowserProvider((window as any).ethereum, 'any');
        setEthereumProvider(provider);
    }, [(window as any).ethereum]);

    return {
        ethereumProvider,
    };
}
