import axios from 'axios';
import { baseUrl } from './config';
import { User } from '../models/User';
import { Key } from '../models/Key';

export interface AllUsersPaginationKey extends Key {
    entityType: 'User';
}

export interface IFilter {
    userName: string;
    email: string;
    adminOnly: boolean;
}

export const getAllUsers = async (
    filter: IFilter,
    lastEvaluatedKey: AllUsersPaginationKey | undefined = undefined,
) => {
    let url = `${baseUrl}/admin/users?limit=10`;
    if (filter.email) {
        url += `&email=${filter.email}`;
    }
    if (filter.userName) {
        url += `&userName=${filter.userName}`;
    }
    if (filter.adminOnly) {
        url += `&adminOnly=${filter.adminOnly}`;
    }
    if (lastEvaluatedKey) {
        url += `&lastEvaluatedId=${lastEvaluatedKey._id}&lastEvaluatedSK=${lastEvaluatedKey.SK}&lastEntityType=${lastEvaluatedKey.entityType}`;
    }
    const res = await axios.get(url);
    return {
        items: res.data.data.items as User[],
        lastEvaluatedKey: res.data.data.LastEvaluatedKey,
    };
};
