const config = {
    local: {
        apiKey: 'AIzaSyC5QRsAK-zbKWdqm7MT770GqHeRY8rdYa8',
        authDomain: 'authmeta-dev.firebaseapp.com',
        projectId: 'authmeta-dev',
        storageBucket: 'authmeta-dev.appspot.com',
        messagingSenderId: '752891220034',
        appId: '1:752891220034:web:8ed819dd26250d312edddb',
        measurementId: 'G-CXB58YZK5M',
    },
    development: {
        apiKey: 'AIzaSyC5QRsAK-zbKWdqm7MT770GqHeRY8rdYa8',
        authDomain: 'authmeta-dev.firebaseapp.com',
        projectId: 'authmeta-dev',
        storageBucket: 'authmeta-dev.appspot.com',
        messagingSenderId: '752891220034',
        appId: '1:752891220034:web:8ed819dd26250d312edddb',
        measurementId: 'G-CXB58YZK5M',
    },
    staging: {
        apiKey: 'AIzaSyBmpyZNSYW17Mshe0V9WRJcwSNFsFcKRBk',
        authDomain: 'authmeta-stage.firebaseapp.com',
        projectId: 'authmeta-stage',
        storageBucket: 'authmeta-stage.appspot.com',
        messagingSenderId: '185675250585',
        appId: '1:185675250585:web:a1986fc8ae933b28263108',
    },
    production: {
        apiKey: 'AIzaSyC1qWOd_CrKcFqZ6eJtZdmH6Un_0g-2n3I',
        authDomain: 'authmeta-prod.firebaseapp.com',
        projectId: 'authmeta-prod',
        storageBucket: 'authmeta-prod.appspot.com',
        messagingSenderId: '680427271971',
        appId: '1:680427271971:web:e533dfec7489b75194fe96',
    },
};

const env = process.env.REACT_APP_STAGE?.trim() ?? process.env.NODE_ENV;
export default config[env];
