import { Box, Container, Grid, Paper, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { User } from '../models/User';
import { getUserDetails } from '../api/getUserDetails';
import { ILandModel } from '../models/Land';
import { UserMetaverses } from '../components/tables/UserMetaverses';
import { IInventoryModel } from '../models/InventoryItem';
import { Key } from '../models/Key';
import { getUsersInventoryItems } from '../api/getUsersInventoryItems';
import { UserInventories } from '../components/tables/UsersInventoryItems';

export const UserDetails = () => {
    const params = useParams();
    const [user, setUser] = useState<User | undefined>(undefined);
    const [lands, setLands] = useState<ILandModel[]>([]);
    const [inventoryItems, setInventoryItems] = useState<IInventoryModel[]>([]);
    const [inventoryLastEvaluatedKey, setInventoryLastEvaluatedKey] = useState<Key | undefined>(undefined);

    useEffect(() => {
        (async () => {
            if (!params.userId) return;
            const res = await getUserDetails(params.userId);
            setUser(res.user);
            setLands(res.lands);

            const inventoryRes = await getUsersInventoryItems(params.userId);
            setInventoryItems(inventoryRes.inventoryItems);
            setInventoryLastEvaluatedKey(inventoryRes.lastEvaluatedKey);
        })();
    }, [params]);

    const loadMoreInventories = useCallback(async () => {
        if (!params.userId || !inventoryLastEvaluatedKey) return;
        const inventoryRes = await getUsersInventoryItems(params.userId, inventoryLastEvaluatedKey);
        setInventoryItems(inventoryRes.inventoryItems);
        setInventoryLastEvaluatedKey(inventoryRes.lastEvaluatedKey);
    }, [params, inventoryLastEvaluatedKey, setInventoryItems, setInventoryLastEvaluatedKey]);

    return (
        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
                marginTop: '64px',
            }}
        >
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        {user && (
                            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                <Typography component="h4" variant="h6" color="InfoText">
                                    User: {user._id.replace('User-', '')}
                                </Typography>
                                <Typography component="h4" variant="subtitle2" color="InfoText">
                                    Email: {user.email ? user.email : 'N/A'}
                                </Typography>
                                <Typography component="h6" variant="subtitle2" color="InfoText">
                                    Email Verified: {user.emailVerified ? 'true' : 'false'}
                                </Typography>
                            </Paper>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        {lands && (
                            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                <UserMetaverses lands={lands} />
                            </Paper>
                        )}
                    </Grid>

                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                            <UserInventories inventoryItems={inventoryItems} loadMore={loadMoreInventories} />
                        </Paper>{' '}
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};
