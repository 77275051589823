import axios from 'axios';
import { baseUrl } from './config';
import { Key } from '../models/Key';
import { IInventoryModel } from '../models/InventoryItem';

export const getUsersInventoryItems = async (
    userId: string,
    lastEvaluatedKey: Key | undefined = undefined,
) => {
    let url = `${baseUrl}/admin/user/${userId}/inventory-items`;
    if (lastEvaluatedKey) {
        url += `?lastEvaluatedId=${lastEvaluatedKey._id}&lastEvaluatedSK=${lastEvaluatedKey.SK}`;
    }
    const res = await axios.get(url);
    return {
        inventoryItems: res.data.data.inventoryItems as IInventoryModel[],
        lastEvaluatedKey: res.data.data.lastEvaluatedKey,
    };
};
