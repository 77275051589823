import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { ILandAsset } from '../../models/LandAsset';

interface ILandAssetsProps {
    assets: ILandAsset[];
}

export const LandAssets = ({ assets }: ILandAssetsProps) => {
    return (
        <>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Assets
            </Typography>{' '}
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Logo</TableCell>
                        <TableCell>Title</TableCell>
                        <TableCell>Position</TableCell>
                        <TableCell>Rotation</TableCell>
                        <TableCell>Scale</TableCell>
                        <TableCell>Object ID (Unreal Asset)</TableCell>
                        <TableCell>Vertices</TableCell>
                        <TableCell align="right">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {assets.map((row) => (
                        <TableRow key={row._id}>
                            <TableCell>
                                {row.thumbnail && <img src={row.thumbnail.url} width="50px" />}
                            </TableCell>
                            <TableCell>{row.title}</TableCell>
                            <TableCell>{row.position}</TableCell>
                            <TableCell>{row.rotation}</TableCell>
                            <TableCell>{row.scale}</TableCell>
                            <TableCell>{row.objectId ?? 'Null'}</TableCell>
                            <TableCell>{row.verticesCount}</TableCell>
                            <TableCell align="right">
                                {row.url && (
                                    <Link to={row.url} target="_blank">
                                        Download
                                    </Link>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
};
