import axios from 'axios';
import { baseUrl } from './config';
import { User } from '../models/User';

export const getUserDetails = async (userId: string) => {
    const url = `${baseUrl}/admin/user/${userId}`;
    const res = await axios.get(url);
    return {
        user: res.data.data.user as User,
        lands: res.data.data.lands,
    };
};
