///user/inventory-items?generalInventory=true

import axios from 'axios';
import { baseUrl } from './config';
import { IInventoryModel } from '../models/InventoryItem';
import { ILandModel } from '../models/Land';
import { InventoryItemLand } from '../models/InventoryItemLand';

export const getInventoryItemDetails = async (
    id: string,
    sk: string,
): Promise<{ inventoryItem: IInventoryModel; lands: ILandModel[]; assets: InventoryItemLand[] }> => {
    const url = `${baseUrl}/inventory-item/${id}/details/${sk}`;
    const res = await axios.get(url);
    return res.data.data;
};
