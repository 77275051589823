import React from 'react';
import './App.css';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { UsersPage } from './pages/UsersPage';
import { Login } from './pages/Login';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LeftSideMenu } from './components/leftSideMenu';
import { Box } from '@mui/material';
import { UserDetails } from './pages/UserDetails';
import { LandDetails } from './pages/LandDetails';
import { Lands } from './pages/Lands';
import { AuthProvider, useAuth } from './providers/AuthProvider';
import { JwtInterceptor } from './providers/JwtInterceptor';
import { CircularProgress } from '@mui/material';
import { DefaultInventoryItems } from './pages/DefaultInventoryItems';
import { InventoryItemDetails } from './pages/InventoryItemDetails';

const mdTheme = createTheme();

const ProtectedRoute = ({ children }) => {
    const auth = useAuth();
    const location = useLocation();

    if (!auth?.token) {
        return !auth?.initializing ? (
            <Navigate to="/login" replace state={{ from: location }} />
        ) : (
            <CircularProgress />
        );
    }

    return children;
};

function App() {
    return (
        <JwtInterceptor>
            <AuthProvider>
                <ThemeProvider theme={mdTheme}>
                    <Box sx={{ display: 'flex' }}>
                        <LeftSideMenu />
                        <Routes>
                            <Route
                                index
                                path="/users"
                                element={
                                    <ProtectedRoute>
                                        <UsersPage />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/user/:userId"
                                element={
                                    <ProtectedRoute>
                                        <UserDetails />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/lands"
                                element={
                                    <ProtectedRoute>
                                        <Lands />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/land/:landId"
                                element={
                                    <ProtectedRoute>
                                        <LandDetails />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/inventory-items"
                                element={
                                    <ProtectedRoute>
                                        <DefaultInventoryItems />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/inventory-item/:id/details/:sk"
                                element={
                                    <ProtectedRoute>
                                        <InventoryItemDetails />
                                    </ProtectedRoute>
                                }
                            />
                            <Route path="*" element={<Navigate to="/users" replace />} />
                            <Route path="/login" element={<Login />} />
                        </Routes>
                    </Box>
                </ThemeProvider>
            </AuthProvider>
        </JwtInterceptor>
    );
}

export default App;
