import {
    Box,
    Button,
    Checkbox,
    Container,
    FormControlLabel,
    Grid,
    Paper,
    TextField,
    Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { AllUsersPaginationKey, IFilter, getAllUsers } from '../api/getAllUsers';
import { Users } from '../components/tables/UsersTable';
import { User } from '../models/User';
import { setIsAdmin } from '../api/setIsAdmin';

const defaultFilters: IFilter = {
    email: '',
    userName: '',
    adminOnly: false,
};

export const UsersPage = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState<AllUsersPaginationKey | undefined>(undefined);
    const [filters, setFilters] = useState<IFilter>(defaultFilters);

    const switchIsAdmin = useCallback(
        async (_id: string, isAdmin: boolean) => {
            await setIsAdmin(_id, isAdmin);
            setUsers((prev) => prev.map((s) => (s._id === _id ? { ...s, isAdmin: isAdmin } : s)));
        },
        [setUsers],
    );

    useEffect(() => {
        (async () => {
            const res = await getAllUsers(filters);

            setUsers(res.items);
            setLastEvaluatedKey(res.lastEvaluatedKey);
        })();
    }, []);

    const loadMore = useCallback(async () => {
        if (!lastEvaluatedKey) {
            return;
        }

        const res = await getAllUsers(filters, lastEvaluatedKey);
        setUsers((prev) => [...prev, ...res.items]);
        setLastEvaluatedKey(res.lastEvaluatedKey);
    }, [lastEvaluatedKey, setUsers, setLastEvaluatedKey]);

    const handleFilterChange = useCallback(
        (property: string, value: string | boolean) => {
            console.log(value);
            setFilters((prev) => ({ ...prev, [property]: value }));
        },
        [setFilters],
    );

    const onApplyClick = useCallback(async () => {
        const res = await getAllUsers(filters);

        setUsers(res.items);
        setLastEvaluatedKey(res.lastEvaluatedKey);
    }, [filters, setUsers, setLastEvaluatedKey]);

    const onResetClick = useCallback(async () => {
        setFilters(defaultFilters);
        const res = await getAllUsers(defaultFilters);

        setUsers(res.items);
        setLastEvaluatedKey(res.lastEvaluatedKey);
    }, [filters, setUsers, setLastEvaluatedKey]);

    return (
        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
                marginTop: '64px',
            }}
        >
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                                Filters
                            </Typography>
                            <Box component="div" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <TextField
                                    id="outlined-x"
                                    label="email"
                                    variant="outlined"
                                    value={filters.email}
                                    size="small"
                                    onChange={(e) => handleFilterChange('email', e.target.value)}
                                />
                                <TextField
                                    id="outlined-y"
                                    label="userName"
                                    variant="outlined"
                                    value={filters.userName}
                                    size="small"
                                    onChange={(e) => handleFilterChange('userName', e.target.value)}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={filters.adminOnly}
                                            onChange={(e) =>
                                                handleFilterChange('adminOnly', e.target.checked)
                                            }
                                            color="secondary"
                                        />
                                    }
                                    label="Admin only"
                                />
                                <div>
                                    <Button
                                        onClick={onApplyClick}
                                        style={{ marginRight: '20px' }}
                                        variant="outlined"
                                    >
                                        Apply
                                    </Button>
                                    <Button onClick={onResetClick} variant="outlined" color="secondary">
                                        Reset
                                    </Button>
                                </div>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                            <Users users={users} loadMore={loadMore} onSwitchAdmin={switchIsAdmin} />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};
