import { Box, Container, Grid, Paper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ILandModel } from '../models/Land';
import { getLandById } from '../api/getLandById';
import { getLandAssets } from '../api/getLandAssets';
import { ILandAsset } from '../models/LandAsset';
import { LandAssets } from '../components/tables/LandAssets';

export const LandDetails = () => {
    const params = useParams();
    const [land, setLand] = useState<ILandModel | undefined>(undefined);
    const [assets, setAssets] = useState<ILandAsset[]>([]);

    useEffect(() => {
        (async () => {
            if (!params.landId) return;
            const res = await getLandById(params.landId);
            const assets = await getLandAssets(params.landId);
            setAssets(assets);
            setLand(res);
        })();
    }, [params]);

    return (
        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
                marginTop: '64px',
            }}
        >
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        {land && (
                            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                <Typography component="h4" variant="h6" color="InfoText">
                                    Land: {land.title}
                                </Typography>
                                <Typography component="h4" variant="subtitle2" color="InfoText">
                                    Pos: {land.pos}
                                </Typography>
                                <Typography component="h6" variant="subtitle2" color="InfoText">
                                    Parent Land:{' '}
                                    {land.parentLandId === 'NOT_EXIST' ? (
                                        'N/A'
                                    ) : (
                                        <Link to={`/land/${land.parentLandId}`}> Parent land </Link>
                                    )}
                                </Typography>
                                <Typography component="h4" variant="subtitle2" color="InfoText">
                                    Owner: {land.ownerAddress ?? 'N/A'}
                                </Typography>
                            </Paper>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                            <LandAssets assets={assets} />
                        </Paper>{' '}
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};
