import { Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { ILandModel } from '../../models/Land';
import { Link } from 'react-router-dom';

interface ILandsTableProps {
    lands: ILandModel[];
    showMore?: () => void;
}

export const LandsTable = ({ lands, showMore }: ILandsTableProps) => {
    return (
        <>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Logo</TableCell>
                        <TableCell>Title</TableCell>
                        <TableCell>Pos</TableCell>
                        <TableCell>Parent</TableCell>
                        <TableCell align="right">Details</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {lands.map((row, i) => (
                        <TableRow key={row._id}>
                            <TableCell>{i + 1}</TableCell>
                            <TableCell>{row.logo && <img src={row.logo.url} width="50px" />}</TableCell>
                            <TableCell>{row.title}</TableCell>
                            <TableCell>{row.pos}</TableCell>
                            <TableCell>
                                {row.parentLandId !== 'NOT_EXIST' ? (
                                    <Link to={`/land/${row.parentLandId}`}> Parent Land </Link>
                                ) : (
                                    'N/A'
                                )}
                            </TableCell>
                            <TableCell align="right">
                                <Link to={`/land/${row._id}`}>Details</Link>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {showMore && (
                <Button color="primary" onClick={() => showMore()} sx={{ mt: 3 }}>
                    Load more
                </Button>
            )}
        </>
    );
};
