import { Box, Container, Grid, Paper, TextField, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ILandModel } from '../models/Land';
import { getLands } from '../api/getLands';
import { LandsTable } from '../components/tables/LandsTable';

interface IFilter {
    x?: number;
    y?: number;
    z?: number;
}

export const Lands = () => {
    const [lands, setLands] = useState<ILandModel[]>([]);
    const [filter, setFilter] = useState<IFilter>({});
    const size = 20;
    const [page, setPage] = useState<number>(1);

    const showMore = useCallback(() => {
        if (page * size > lands.length) return;

        setPage((prev) => prev + 1);
    }, [page, setPage, lands]);

    const handlePositionChange = (name: string, value: string) => {
        const numberValue = value ? +value : null;
        setFilter((prev) => ({ ...prev, [name]: numberValue }));
    };

    useEffect(() => {
        (async () => {
            const res = await getLands();

            setLands(res);
        })();
    }, []);

    const landsToDisplay = useMemo(() => {
        let res = lands.map((s) => s);
        if (filter.x || filter.y || filter.z) {
            const regex = new RegExp(`^${filter.x ?? '-?\\d'},${filter.y ?? '-?\\d'},${filter.z ?? '-?\\d'}`);
            res = lands.filter((s) => s.pos?.match(regex));
        }
        return res.slice(0, page * size);
    }, [lands, page, filter]);

    return (
        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
                marginTop: '64px',
            }}
        >
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', marginBottom: '15px' }}>
                            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                                Filters
                            </Typography>{' '}
                            <Box component="div" sx={{ display: 'flex', justifyContent: 'space-around' }}>
                                <TextField
                                    id="outlined-x"
                                    label="x"
                                    variant="outlined"
                                    size="small"
                                    onChange={(e) => handlePositionChange('x', e.target.value)}
                                />
                                <TextField
                                    id="outlined-y"
                                    label="y"
                                    variant="outlined"
                                    size="small"
                                    onChange={(e) => handlePositionChange('y', e.target.value)}
                                />
                                <TextField
                                    id="outlined-z"
                                    label="z"
                                    variant="outlined"
                                    size="small"
                                    onChange={(e) => handlePositionChange('z', e.target.value)}
                                />
                            </Box>
                        </Paper>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                            <LandsTable lands={landsToDisplay} showMore={showMore} />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};
