import { Box, Button, CircularProgress, Container, Grid, Paper, Typography } from '@mui/material';
import { useAuth } from '../providers/AuthProvider';

export const Login = () => {
    const auth = useAuth();
    return (
        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
                marginTop: '64px',
            }}
        >
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                            <Button onClick={auth?.onLogin}>Login</Button>
                            {auth?.initializing && <CircularProgress />}
                            {auth?.isAdmin === false && (
                                <Typography align="center" component="h4" variant="subtitle2">
                                    You are not eligable to access admin page. Please contact you
                                    administrator to get appropriate role
                                </Typography>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};
